@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

#no-scrollbar::-webkit-scrollbar {
    display: none;
}

#loading_guy {
    border: solid 4px;
    border-color:#058828 #058828 transparent transparent;
}